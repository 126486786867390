<messages>["./CurrencyConverter"]</messages>

<template>
  <base-dialog
    v-model="visible"
    mw0
    persistent
    close-on-esc>
    <v-card>
      <form @submit.prevent="onSubmit">
        <v-card-title class="text-h5">
          {{ $t ('title', {currency: currencyStr}) }}
        </v-card-title>

        <v-card-text>
          <v-row>
            {{ $t('label.current') }}:
            {{ formatMoneyAmount (max, currency) }}
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                ref="srcAmount"
                v-model="srcAmount"
                type="number"
                step="0.0001"
                :min="max ? 0.01 : null"
                :max="max"
                :disabled="!!amount"
                :label="$t ('label.amount', {currency: currencyStr})"
                :error-messages="validationErrors (
                  'srcAmount', {
                    required: 'general.required',
                    range: {
                      key: 'general.invalid.range',
                      params: {min: formatMoneyAmount (0.01, currency), max: formatMoneyAmount (max, currency)}
                    }
                  })"
                @blur="$v.srcAmount.$touch"/>
            </v-col>

            <v-col
              cols="12" sm="2"
              class="text-center">
              <v-icon
                large
                class="conversionDirectionIcon">
                trending_flat
              </v-icon>
              <span
                v-show="!!exchangeRate"
                class="exchangeRate">
                {{ $t ('exchangeRate', {value: exchangeRate}) }}
              </span>
            </v-col>

            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="3">
                  <v-progress-circular
                    v-if="loadingInfo"
                    indeterminate
                    class="load-progress"
                    color="primary"/>
                  <v-text-field
                    v-else
                    disabled
                    class="dstAmount text-right"
                    :value="dstAmount"/>
                </v-col>
                <v-col cols="9">
                  <currency-select
                    ref="dstCurrency"
                    v-model="dstCurrency"
                    :exclude="[currency]"
                    :label="$t ('label.destinationCurrency')"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.cancel'"
            text
            @click.native="onCancel"/>
          <v-btn
            v-t="'general.button.ok'"
            color="primary"
            type="submit"
            :disabled="!isConversionDataReady"/>
        </v-card-actions>
      </form>
    </v-card>
  </base-dialog>
</template>

<script>
  import BaseDialog from '@/app/core/components/BaseDialog'
  import CurrencySelect from '@/app/core/components/CurrencySelect'

  import _debounce from 'lodash/debounce'
  import {mapMutations, mapActions} from 'vuex'

  import {between, required} from 'vuelidate/lib/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  export default {
    name: 'CurrencyConverterDialog',

    components: {BaseDialog, CurrencySelect},

    mixins: [validationMixins],

    props: {
      // dialog visibility
      value: Boolean,
      // the currency to convert from
      currency: {
        type: String,
        default: undefined
      },
      // initial amount to convert
      amount: {
        type: Number,
        default: undefined
      },
      // maximal allowed amount value
      max: {
        type: Number,
        default: undefined
      }
    },

    data () {
      return {
        loadingInfo: false,
        srcAmount: null,
        dstCurrency: null,
        conversionInfo: null
      }
    },

    validations () {
      return {
        srcAmount: {
          required,
          ...(this.amount ? {} : {range: between (0.01, this.max)})
        }
      }
    },

    computed: {
      visible: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      },

      currencyStr () {
        return this.formatCurrency (this.currency)
      },

      conversionInfoData () {
        return {
          sourceCurrency: this.currency,
          destinationCurrency: this.dstCurrency,
          amount: this.srcAmount
        }
      },

      moneyExchangeData () {
        return {
          ...this.conversionInfoData,
          expectedAmount: this.dstAmount
        }
      },

      isConversionInfoDataValid () {
        return this.currency && this.dstCurrency && this.srcAmount
      },

      isConversionDataReady () {
        return this.isConversionInfoDataValid && !this.$v.$invalid
      },

      dstAmount () {
        return this.conversionInfo?.amount
      },

      exchangeRate () {
        return this.conversionInfo?.exchangeRate
      }
    },

    watch: {
      value (newValue) {
        if (newValue) {
          this.focus ()
        }
      },

      currency (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.reset ()
        }
      },

      amount (newValue) {
        this.srcAmount = newValue

        if (this.conversionInfo?.amount) {
          this.conversionInfo.amount = undefined
        }
      },

      conversionInfoData (newValue, oldValue) {
        const {destinationCurrency} = newValue
        const {destinationCurrency: dstCurrencyOld} = oldValue

        if (destinationCurrency !== dstCurrencyOld && this.exchangeRate) {
          this.conversionInfo.exchangeRate = undefined
        }

        if (this.isConversionInfoDataValid) {
          this.loadingInfo = true

          if (this.dstAmount) {
            this.conversionInfo.amount = undefined
          }

          this.getConversionInfo ()
        }
      }
    },

    created () {
      this.reset ()
    },

    methods: {
      ...mapMutations ({
        displayErrorMessage: 'notification/setError',
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      reset () {
        this.loadingInfo = false
        this.srcAmount = null
        this.dstCurrency = null
        this.conversionInfo = null
        this.$v.$reset ()
      },

      focus () {
        this.$nextTick (() => {
          const elToFocus = this.amount ? 'dstCurrency' : 'srcAmount'
          this.$refs[elToFocus].focus ()
        })
      },

      getConversionInfo: _debounce (function () {
        this.fetchData ({
          op: 'client/data/currency-conversion-info',
          params: {data: this.conversionInfoData},
          cb: data => {
            this.conversionInfo = data.data
          },
          cbFinal: () => {
            this.loadingInfo = false
          }
        })
      }, 1000),

      /**
       * handler for 'cancel' button
       */
      onCancel () {
        this.visible = false
      },

      /**
       * handler for 'ok' button
       */
      onSubmit () {
        this.fetchData ({
          op: 'client/data/money-exchange',
          params: {data: this.moneyExchangeData},
          cb: data => {
            const from = this.formatMoneyAmount (this.srcAmount, this.currency)
            const to = this.formatMoneyAmount (data.value, this.dstCurrency)
            this.displaySuccessMessage (this.$t ('success', {from, to}))

            this.$emit ('converted')

            this.visible = false
          },
          cbError: () => {
            this.displayErrorMessage (this.$t ('error'))
          }
        })
      }
    }
  }
</script>

<style scoped>
.conversionDirectionIcon {
  display: block;
}

.exchangeRate {
  display: block;
}
</style>
