<template>
  <v-row no-gutters align="center">
    <v-col md="10" sm="9" cols="9" class="text-right">
      {{ value }}
    </v-col>
    <v-col md="2" sm="3" cols="3"/>
  </v-row>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      }
    }
  }
</script>
