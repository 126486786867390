var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"mw0":"","persistent":"","close-on-esc":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t ('title', {currency: _vm.currencyStr}))+" ")]),_c('v-card-text',[_c('v-row',[_vm._v(" "+_vm._s(_vm.$t('label.current'))+": "+_vm._s(_vm.formatMoneyAmount (_vm.max, _vm.currency))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{ref:"srcAmount",attrs:{"type":"number","step":"0.0001","min":_vm.max ? 0.01 : null,"max":_vm.max,"disabled":!!_vm.amount,"label":_vm.$t ('label.amount', {currency: _vm.currencyStr}),"error-messages":_vm.validationErrors (
                'srcAmount', {
                  required: 'general.required',
                  range: {
                    key: 'general.invalid.range',
                    params: {min: _vm.formatMoneyAmount (0.01, _vm.currency), max: _vm.formatMoneyAmount (_vm.max, _vm.currency)}
                  }
                })},on:{"blur":_vm.$v.srcAmount.$touch},model:{value:(_vm.srcAmount),callback:function ($$v) {_vm.srcAmount=$$v},expression:"srcAmount"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{staticClass:"conversionDirectionIcon",attrs:{"large":""}},[_vm._v(" trending_flat ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.exchangeRate),expression:"!!exchangeRate"}],staticClass:"exchangeRate"},[_vm._v(" "+_vm._s(_vm.$t ('exchangeRate', {value: _vm.exchangeRate}))+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[(_vm.loadingInfo)?_c('v-progress-circular',{staticClass:"load-progress",attrs:{"indeterminate":"","color":"primary"}}):_c('v-text-field',{staticClass:"dstAmount text-right",attrs:{"disabled":"","value":_vm.dstAmount}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('currency-select',{ref:"dstCurrency",attrs:{"exclude":[_vm.currency],"label":_vm.$t ('label.destinationCurrency')},model:{value:(_vm.dstCurrency),callback:function ($$v) {_vm.dstCurrency=$$v},expression:"dstCurrency"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.cancel'),expression:"'general.button.cancel'"}],attrs:{"text":""},nativeOn:{"click":function($event){return _vm.onCancel($event)}}}),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.ok'),expression:"'general.button.ok'"}],attrs:{"color":"primary","type":"submit","disabled":!_vm.isConversionDataReady}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }