var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"mw0":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error","value":!_vm.isLoading && !_vm.client}},[_vm._v(" "+_vm._s(_vm.$t ('invalidClientId', {id: _vm.id}))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.client)?_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{directives:[{name:"t",rawName:"v-t",value:('title.view'),expression:"'title.view'"}],staticClass:"text-h5"}),_c('div',{directives:[{name:"t",rawName:"v-t",value:("subtitle.view"),expression:"`subtitle.view`"}],staticClass:"cgwng-subheading"})])]),(!_vm.isLoading)?_c('v-card-text',{staticClass:"px-4"},[_c('name-value-block',{attrs:{"items":[
            {name: _vm.$t ('label.id'), value: _vm.client.id},
            {name: _vm.$t ('label.type'), value: _vm.$t (("label.clientType." + (_vm.clientTypes[_vm.client.type])))},
            {name: _vm.$t ('label.parent'), value: _vm.parentName},
            {name: _vm.$t ('label.registrarId'), value: _vm.getRegistrarById (_vm.client.registrarId).text},
            {name: _vm.$t ('label.name'), value: _vm.client.name},
            {name: _vm.$t ('label.street'), value: _vm.client.street},
            {name: _vm.$t ('label.postalCode'), value: _vm.client.postalCode},
            {name: _vm.$t ('label.city'), value: _vm.client.city},
            {name: _vm.$t ('label.state'), value: _vm.client.state},
            {name: _vm.$t ('label.country'), value: _vm.client.countryCode},
            {name: _vm.$t ('label.email'), value: _vm.client.email},
            {name: _vm.$t ('label.pollNotificationEmail'), value: _vm.client.pollEmail},
            {name: _vm.$t ('label.phone'), value: _vm.client.phone},
            {name: _vm.$t ('label.fax'), value: _vm.client.fax},
            {name: _vm.$t ('label.webUrl'), value: _vm.client.webUrl},
            {name: _vm.$t ('label.freeText'), value: _vm.client.freeText},
            {name: _vm.$t ('label.providerHandle'), value: _vm.client.providerHandle},
            {name: _vm.$t ('label.providerChain'), value: _vm.client.defaultProviderChain}
          ]}}),_c('div',{staticClass:"nameValueBlock"},[_c('div',{staticClass:"text-subtitle-1 primary--text mt-3",domProps:{"textContent":_vm._s(_vm.$t('label.groupsAndServices'))}}),_c('name-value',{attrs:{"name":this.$t ('label.groups')}},[_c('client-group-info-menu',{attrs:{"slot":"value","groups":_vm.client.groups,"group-names":_vm.clientGroupNames},slot:"value"})],1),_c('name-value',{attrs:{"name":this.$t ('label.services')}},[_c('services-info-item',{attrs:{"slot":"value","enabled-services":_vm.client.enabledServices},slot:"value"})],1)],1),(_vm.clientIsMemberOrPartner)?[_c('name-value-block',{staticClass:"mt-3",attrs:{"title":_vm.$t ('subsection.account'),"items":_vm.accountItems}}),_c('div',{staticClass:"text-subtitle-1 primary--text mt-3",domProps:{"textContent":_vm._s(_vm.$t('label.immediateConversionMappings'))}}),_c('currecy-config',{attrs:{"value":_vm.client.immediateConversionMappings,"disabled":"","no-title":""}}),_c('name-value-block',{staticClass:"mt-3",attrs:{"title":_vm.$t ('subsection.currentVat.coreHub'),"items":_vm.vatDataCoreHub}}),_c('name-value-block',{staticClass:"mt-3",attrs:{"title":_vm.$t ('subsection.currentVat.coreAss'),"items":_vm.vatDataCoreAss}}),_c('name-value-block',{staticClass:"mt-3",attrs:{"title":_vm.$t ('subsection.scheduledVat.coreHub'),"items":[
              {name: _vm.$t('label.futureVatStart'), value: _vm.formatDateShort (_vm.client.coreHubScheduledStartDate)} ].concat( (_vm.client.coreHubScheduledStartDate ? _vm.vatScheduledDataCoreHub : [])
            )}}),_c('name-value-block',{staticClass:"mt-3",attrs:{"title":_vm.$t ('subsection.scheduledVat.coreAss'),"items":[
              {name: _vm.$t('label.futureVatStart'), value: _vm.formatDateShort (_vm.client.coreAssScheduledStartDate)} ].concat( (_vm.client.coreAssScheduledStartDate ? _vm.vatScheduledDataCoreAss : [])
            )}}),_c('name-value-block',{staticClass:"mt-3",attrs:{"title":_vm.$t ('subsection.further'),"items":[
              {name: _vm.$t('label.purchaseOrderHub'), value: _vm.client.coreHubInvoiceData.purchaseOrder},
              {name: _vm.$t('label.purchaseOrderEndHub'), value: _vm.client.coreHubInvoiceData.purchaseOrderEnd},
              {name: _vm.$t('label.paymentOrderHub'), value: _vm.client.coreHubInvoiceData.paymentOrder},
              {name: _vm.$t('label.paymentOrderEndHub'), value: _vm.client.coreHubInvoiceData.paymentOrderEnd},
              {name: _vm.$t('label.purchaseOrderAss'), value: _vm.client.coreAssInvoiceData.purchaseOrder},
              {name: _vm.$t('label.purchaseOrderEndAss'), value: _vm.client.coreAssInvoiceData.purchaseOrderEnd},
              {name: _vm.$t('label.paymentOrderAss'), value: _vm.client.coreAssInvoiceData.paymentOrder},
              {name: _vm.$t('label.paymentOrderEndAss'), value: _vm.client.coreAssInvoiceData.paymentOrderEnd}
            ]}}),_c('name-value-block',{staticClass:"mt-3",attrs:{"title":'\xa0',"items":[
              {name: _vm.$t('label.transferLanguages'), value: _vm.client.transferLanguages.map (function (lang) { return _vm.$t (("label.language." + lang)); })},
              {name: _vm.$t('label.whoisWhitelist'), value: _vm.client.whoisWhitelist}
            ]}}),_c('name-value-block',{staticClass:"mt-3",attrs:{"title":_vm.$t ('subsection.otp'),"items":[
              {name: _vm.$t('label.otpRequired'), value: _vm.client.otpRequired}
            ]}})]:_vm._e()],2):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.back'),expression:"'general.button.back'"}],attrs:{"text":""},on:{"click":_vm.back}}),(_vm.notMe)?_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('client.impersonate.title'),expression:"'client.impersonate.title'"}],attrs:{"disabled":_vm.isImpersonated,"loading":_vm.isLoadingImpersonation},on:{"click":function($event){_vm.onImpersonateClient (_vm.client)}}}):_vm._e(),(_vm.isAccAdmin && !_vm.clientIsReseller)?_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('label.createLimit'),expression:"'label.createLimit'"}],attrs:{"to":{name: 'creditlimit.create', query: {id: _vm.client.id}}}}):_vm._e(),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.edit'),expression:"'general.button.edit'"}],attrs:{"to":{name: 'client.edit', params: {id: _vm.id}},"color":"primary"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }