var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","dark":!_vm.isOwn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var outer = ref.on;
return [_vm._t("activator",[(!_vm.disableActions)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var inner = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.loading,"text":""}},Object.assign({}, outer, inner)),[_c('span',{class:{warn: _vm.sumItem.warn},domProps:{"textContent":_vm._s(_vm.sumItem.value)}})])]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('balance'),expression:"'balance'"}]})]):_vm._e()],{"on":outer})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',[(_vm.loading)?_c('v-list-item',[_c('v-list-item-title',{staticClass:"text-center"},[_c('v-progress-linear',{staticClass:"load-progress",attrs:{"indeterminate":"","color":"primary"}})],1)],1):[_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t ('title', {client: _vm.clientName}))+" ")])])],1),_c('v-divider'),(_vm.balancesNotAvailable)?_c('v-list-item',[_c('v-list-item-title',[_c('i',[_vm._v(_vm._s(_vm.$t (_vm.balancesError ? 'error' : 'notAvailable')))])])],1):[_c('v-subheader',[_vm._v(_vm._s(_vm.totalTitle))]),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.totalBelowMinBalance},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-title',{staticClass:"text-h6 text-left"},[_vm._v(" "+_vm._s(_vm.sumItem.currency)+" ")]),_c('v-list-item-title',{class:['title text-right', {warn: _vm.sumItem.warn}]},[_vm._v(" "+_vm._s(_vm.sumItem.value)+" ")])],1)]}}])},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('total.belowMin'),expression:"'total.belowMin'"}]})]),(_vm.balanceItems.length > 0)?_c('v-subheader',{directives:[{name:"t",rawName:"v-t",value:('currencies'),expression:"'currencies'"}]}):_vm._e(),_vm._l((_vm.balanceItems),function(balance,index){return [(index > 0 && (balance.currency < _vm.balanceItems[index-1].currency))?_c('v-divider',{key:("d-" + (balance.currency))}):_vm._e(),_c('v-list-item',{key:("b-" + (balance.currency)),attrs:{"color":balance.color}},[_c('v-list-item-title',{staticClass:"text-left"},[_vm._v(" "+_vm._s(balance.currency)+" ")]),_c('v-list-item-title',{staticClass:"text-right",style:({color: balance.color}),attrs:{"title":balance.text}},[_vm._v(" "+_vm._s(balance.text)+" ")]),(!_vm.disableActions && _vm.actionButtons (balance).length)?_c('v-list-item-action',{staticClass:"actions"},_vm._l((_vm.actionButtons (balance)),function(button){return _c('v-tooltip',{key:button.icon,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"small":"","icon":"","outlined":""},on:{"click":function($event){button.action (balance)}}},on),[_c('v-icon',[_vm._v(_vm._s(button.icon))])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(button.titleKey),expression:"button.titleKey"}]})])}),1):_vm._e()],1)]})]],(_vm.creditLimit || _vm.warningThreshold)?_c('v-divider'):_vm._e(),(_vm.creditLimit)?_c('v-row',{staticClass:"pa-3",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"align-center align-content-center justify-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" pan_tool ")]),_vm._v(" "+_vm._s(_vm.$t ('creditLimit'))+": ")],1),_c('v-col',{staticClass:"shrink text-no-wrap"},[_vm._v(" "+_vm._s(_vm.formatMoneyAmount (_vm.creditLimit.value, _vm.creditLimit.currency))+" ")])],1):_vm._e(),(_vm.warningThreshold)?_c('v-row',{staticClass:"pa-3",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"align-center align-content-center justify-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" notifications_active ")]),_vm._v(" "+_vm._s(_vm.$t ('warningThreshold'))+": ")],1),_c('v-col',{staticClass:"shrink"},[_vm._v(" "+_vm._s(_vm.formatMoneyAmount (_vm.warningThreshold.value, _vm.warningThreshold.currency))+" ")])],1):_vm._e()],2)],1),_c('currency-converter-dialog',{attrs:{"currency":_vm.currencyConverter.currency,"amount":_vm.currencyConverter.amount,"max":_vm.currencyConverter.max},model:{value:(_vm.currencyConverter.visible),callback:function ($$v) {_vm.$set(_vm.currencyConverter, "visible", $$v)},expression:"currencyConverter.visible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }