<messages>["./components/ClientCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw0>
    <!-- "client does not exist" alert -->
    <v-col cols="12">
      <v-alert
        type="error"
        :value="!isLoading && !client">
        {{ $t ('invalidClientId', {id}) }}
      </v-alert>
    </v-col>

    <v-col cols="12">
      <v-card v-if="client">
        <v-card-title primary-title>
          <div>
            <div
              v-t="'title.view'"
              class="text-h5"/>
            <div
              v-t="`subtitle.view`"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>

        <v-card-text v-if="!isLoading" class="px-4">
          <name-value-block
            :items="[
              {name: $t ('label.id'), value: client.id},
              {name: $t ('label.type'), value: $t (`label.clientType.${clientTypes[client.type]}`)},
              {name: $t ('label.parent'), value: parentName},
              {name: $t ('label.registrarId'), value: getRegistrarById (client.registrarId).text},
              {name: $t ('label.name'), value: client.name},
              {name: $t ('label.street'), value: client.street},
              {name: $t ('label.postalCode'), value: client.postalCode},
              {name: $t ('label.city'), value: client.city},
              {name: $t ('label.state'), value: client.state},
              {name: $t ('label.country'), value: client.countryCode},
              {name: $t ('label.email'), value: client.email},
              {name: $t ('label.pollNotificationEmail'), value: client.pollEmail},
              {name: $t ('label.phone'), value: client.phone},
              {name: $t ('label.fax'), value: client.fax},
              {name: $t ('label.webUrl'), value: client.webUrl},
              {name: $t ('label.freeText'), value: client.freeText},
              {name: $t ('label.providerHandle'), value: client.providerHandle},
              {name: $t ('label.providerChain'), value: client.defaultProviderChain}
            ]"/>
          <div class="nameValueBlock">
            <div
              class="text-subtitle-1 primary--text mt-3"
              v-text="$t('label.groupsAndServices')"/>
            <name-value :name="this.$t ('label.groups')">
              <client-group-info-menu
                slot="value"
                :groups="client.groups"
                :group-names="clientGroupNames"/>
            </name-value>
            <name-value :name="this.$t ('label.services')">
              <services-info-item slot="value" :enabled-services="client.enabledServices"/>
            </name-value>
          </div>
          <template
            v-if="clientIsMemberOrPartner">
            <!-- div class="headline" v-text="$t ('section.billingData')"></div -->
            <name-value-block
              class="mt-3"
              :title="$t ('subsection.account')"
              :items="accountItems"/>

            <div
              class="text-subtitle-1 primary--text mt-3"
              v-text="$t('label.immediateConversionMappings')"/>
            <currecy-config :value="client.immediateConversionMappings" disabled no-title/>

            <name-value-block
              class="mt-3"
              :title="$t ('subsection.currentVat.coreHub')"
              :items="vatDataCoreHub"/>

            <name-value-block
              class="mt-3"
              :title="$t ('subsection.currentVat.coreAss')"
              :items="vatDataCoreAss"/>

            <name-value-block
              class="mt-3"
              :title="$t ('subsection.scheduledVat.coreHub')"
              :items="[
                {name: $t('label.futureVatStart'), value: formatDateShort (client.coreHubScheduledStartDate)},
                ...(client.coreHubScheduledStartDate ? vatScheduledDataCoreHub : [])
              ]"/>

            <name-value-block
              class="mt-3"
              :title="$t ('subsection.scheduledVat.coreAss')"
              :items="[
                {name: $t('label.futureVatStart'), value: formatDateShort (client.coreAssScheduledStartDate)},
                ...(client.coreAssScheduledStartDate ? vatScheduledDataCoreAss : [])
              ]"/>
            <name-value-block
              class="mt-3"
              :title="$t ('subsection.further')"
              :items="[
                {name: $t('label.purchaseOrderHub'), value: client.coreHubInvoiceData.purchaseOrder},
                {name: $t('label.purchaseOrderEndHub'), value: client.coreHubInvoiceData.purchaseOrderEnd},
                {name: $t('label.paymentOrderHub'), value: client.coreHubInvoiceData.paymentOrder},
                {name: $t('label.paymentOrderEndHub'), value: client.coreHubInvoiceData.paymentOrderEnd},
                {name: $t('label.purchaseOrderAss'), value: client.coreAssInvoiceData.purchaseOrder},
                {name: $t('label.purchaseOrderEndAss'), value: client.coreAssInvoiceData.purchaseOrderEnd},
                {name: $t('label.paymentOrderAss'), value: client.coreAssInvoiceData.paymentOrder},
                {name: $t('label.paymentOrderEndAss'), value: client.coreAssInvoiceData.paymentOrderEnd}
              ]"/>
            <name-value-block
              class="mt-3"
              :title="'\xa0'"
              :items="[
                {name: $t('label.transferLanguages'), value: client.transferLanguages.map (lang => $t (`label.language.${lang}`))},
                {name: $t('label.whoisWhitelist'), value: client.whoisWhitelist}
              ]"/>
            <name-value-block
              class="mt-3"
              :title="$t ('subsection.otp')"
              :items="[
                {name: $t('label.otpRequired'), value: client.otpRequired}
              ]"/>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.back'"
            text
            @click="back"/>
          <v-btn
            v-if="notMe"
            v-t="'client.impersonate.title'"
            :disabled="isImpersonated"
            :loading="isLoadingImpersonation"
            @click="onImpersonateClient (client)"/>
          <v-btn
            v-if="isAccAdmin && !clientIsReseller"
            v-t="'label.createLimit'"
            :to="{name: 'creditlimit.create', query: {id: client.id}}"/>
          <v-btn
            v-t="'general.button.edit'"
            :to="{name: 'client.edit', params: {id}}"
            color="primary"/>
        </v-card-actions>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

  import {EmptyMark} from '@/app/utils/string'
  import {formatDateShort} from '@/app/core/mixins/DatetimeHelper'
  import ServicesInfoItem from './components/ServicesInfoItem'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import NameValue from '@/app/core/components/NameValue'

  import AccountBalanceInfo from '@/app/core/components/App/AccountBalanceInfo'
  import TwoColumnValue from '@/app/core/components/TwoColumnValue'

  import ClientGroupInfoMenu from './components/AuthorizationGroup/ClientGroupInfoMenu'
  import CurrecyConfig from './components/CurrencyConfig'

  export default {
    name: 'ClientView',

    components: {
      BaseLayout,
      NameValueBlock,
      NameValue,
      ServicesInfoItem,
      ClientGroupInfoMenu,
      CurrecyConfig
    },

    props: {
      id: {
        type: [Number, String],
        required: true
      }
    },

    data () {
      return {
        isLoading: true,
        client: undefined,
        clientTypes: ['', 'member', 'reseller', 'partner', 'internal', 'agent'],
        parentName: '',
        registrars: [],
        clientGroups: [],
        isLoadingImpersonation: false,
        dialog: false,
        serviceDialog: false
      }
    },

    computed: {
      ...mapState ('auth', [
        'userLoginData',
        'isImpersonated'
      ]),

      ...mapGetters ('auth', [
        'hasAllOfPermissions'
      ]),

      isAccAdmin () {
        return this.hasAllOfPermissions (['DoAccounting', 'ManageAllEntities'])
      },

      vatDataCoreHub () {
        return this.getVatData ('coreHubTaxInfo')
      },

      vatDataCoreAss () {
        return this.getVatData ('coreAssTaxInfo')
      },

      vatScheduledDataCoreHub () {
        return this.getVatData ('coreHubScheduledTaxInfo', true)
      },

      vatScheduledDataCoreAss () {
        return this.getVatData ('coreAssScheduledTaxInfo', true)
      },

      groupItem () {
        return {
          name: this.$t ('label.groups'),
          value: true,
          slotValue: this.$createElement (ClientGroupInfoMenu, {
            props: {
              groups: this.client.groups,
              groupNames: this.clientGroupNames
            }
          })
        }
      },

      accountItems () {
        const item = prop => (this.client[prop] !== null
          ? [{
            name: this.$t (`label.${prop}`),
            value: true,
            slotValue: this.$createElement (TwoColumnValue, {
              props: {
                value: this.formatMoneyAmount (
                  this.client[prop],
                  this.client.currency)
              }
            })
          }]
          : [])

        return [
          ...item ('warnThreshold'),
          ...item ('minBalance'),
          ...['member', 'partner', 'agent', 'internal']
            .includes (this.clientTypes[this.client.type])
            ? [{
                 name: this.$t ('label.balance'),
                 value: true,
                 slotValue: this.$createElement (AccountBalanceInfo, {
                   props: {
                     clientType: this.clientTypes[this.client.type],
                     clientId: this.client.id,
                     disableActions: true
                   }
                 })
               },
               ...item ('currentCreditLimit')
            ]
            : []
        ]
      },

      clientGroupNames () {
        let groupNames = []
        if (this.client.groups?.length > 0) {
          if (this.clientGroups.length > 0) {
            groupNames = this.clientGroups.filter (g =>
              this.client.groups.includes (g.id)).map (g => g.name)
          } else {
            groupNames = this.client.groups
          }
        }

        return groupNames
      },

      clientIsMemberOrPartner () {
        return this.client.type === 1 || this.client.type === 3
      },

      clientIsReseller () {
        return this.client.type === 2
      },

      notMe () {
        return this.userLoginData.clientId !== this.client.id
      }
    },

    watch: {
      id (newValue) {
        this.populateDataForClient (newValue)
      }
    },

    created () {
      this.populateDataForClient (this.id)
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        setEffectiveClientData: 'auth/setEffectiveClientData'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData',
        getClientNameExt: 'cache/getClientNameExt',
        impersonate: 'auth/impersonateClient'
      }),

      formatDateShort,

      getVatData (subName, showNullValue = false) {
        const fields = ['vatId', 'vatMode', 'vatInvoiceText', 'vatRegime']

        return [
          ...fields.map (
            (field) => ({
              name: this.$t (`label.${field}`),
              value: this.client[subName]?.[field] || (showNullValue ? EmptyMark : undefined)
            })),

          ...this.client?.[subName]?.taxRates
            ? this.client[subName].taxRates.map (vatRate => (
              {
                name: vatRate.name,
                value: `${vatRate.rate}%`
              }
            ))
            : []
        ]
      },

      loadClient (id) {
        return this.fetchData ({
          op: 'client/load',
          params: {id},
          cb: data => {
            this.client = data.clientData
          },
          cbError: () => {
            this.client = null
          }
        })
      },

      setServiceDialog (v) {
        this.serviceDialog = v
      },

      async loadClientInfo (clientId) {
        this.parentName = await this.getClientNameExt (clientId)
      },

      // retrieve the list of registrars
      listRegistrars () {
        return this.fetchData ({
          op: 'registrar/list',
          cb: data => {
            const regObjects = data.registrars
            // add 'text' property to be used as label
            this.registrars = regObjects.map (
              elem => ({
                ...elem,
                text: elem.displayName + (elem.ianaId ? ` (${elem.ianaId})` : '')
              })
            )
          }
        })
      },

      getRegistrarById (registrarId) {
        return this.registrars.find (r => r.id === registrarId)
      },

      // retrieve the list of client groups
      loadClientGroups () {
        return this.fetchData ({
          op: 'client/data/groups',
          params: {
            id: this.client.id
          },
          cb: data => {
            this.clientGroups = data.list
          }
        })
      },

      populateDataForClient (id) {
        this.isLoading = true
        Promise.all ([
          this.loadClient (id),
          this.listRegistrars ()
        ]).then (() => {
          this.isLoading = false
          if (this.client.parent) {
            this.loadClientInfo (this.client.parent)
          }
          this.loadClientGroups ()
        })
      },

      /**
       * impersonate specified client
       *
       * @param client      client to be impersonated
       */
      async onImpersonateClient (client) {
        this.isLoadingImpersonation = true
        const success = await this.impersonate (client.id)

        if (success) {
          this.setEffectiveClientData (client)

          this.displaySuccessMessage (
            this.$t ('client.impersonate.success', {client: client.name}))

          this.isLoadingImpersonation = true

          this.$router.push ({name: 'dashboard'})
        }
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>

<style scoped>
:global(.nameValueBlock) {
  margin-bottom: 24px;
}

.subtitle-1 {
  text-align: center
}
</style>
