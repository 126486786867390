<template>
  <v-row no-gutters align="center">
    <v-col class="text-right" md="10" sm="9" cols="9">
      <span :class="{'red--text': total.warn}">
        {{ total.value }}
      </span>
    </v-col>
    <v-col md="2" sm="3" cols="3" class="text-right">
      <account-balance
        :client-type="clientType"
        :client-id="clientId"
        :disable-actions="disableActions"
        :is-own="isOwn"
        @loaded="loaded">
        <template #activator="{ on: outer }">
          <v-tooltip top>
            <template #activator="{ on: inner }">
              <v-icon v-on="{ ...outer, ...inner }">
                info
              </v-icon>
            </template>
            {{ $t ('general.label.clickInfo') }}
          </v-tooltip>
        </template>
      </account-balance>
    </v-col>
  </v-row>
</template>

<script>
  import AccountBalance from './AccountBalance'

  export default {
    name: 'AccountBalanceInfo',

    components: {
      AccountBalance
    },

    props: {
      clientType: {
        type: String,
        required: true
      },
      clientId: {
        type: Number,
        required: true
      },
      isOwn: {
        type: Boolean,
        default: false
      },
      disableActions: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        total: {
          value: '-'
        }
      }
    },

    methods: {
      loaded (e) {
        this.total = e.sumItem
      }
    }
  }
</script>
