<messages>["./ClientCreateUpdate"]</messages>

<template>
  <v-row no-gutters align="center">
    <v-col md="10" sm="9" cols="9">
      {{ serviceOptions }}
    </v-col>
    <v-col md="2" sm="3" cols="3" class="text-right">
      <services-help-dialog
        v-model="dialog"
        :options="enabledServices"/>
    </v-col>
  </v-row>
</template>

<script>
  import ServicesHelpDialog from './ServicesHelpDialog'

  export default {
    components: {
      ServicesHelpDialog
    },

    props: {
      enabledServices: {
        type: Array,
        default: () => ([])
      }
    },

    data () {
      return {
        dialog: false
      }
    },

    computed: {
      serviceOptions () {
        return this.enabledServices.map (item =>
          this.$t (`service.${item}`)).sort ((u1, u2) => u1.localeCompare (u2))
          .reduce ((acc, v) => acc === '' ? v : acc + ', ' + v, '')
      }
    }
  }
</script>
