<messages>["./AccountBalance"]</messages>

<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      :dark="!isOwn">
      <template #activator="{ on: outer }">
        <slot name="activator" :on="outer">
          <v-tooltip
            v-if="!disableActions"
            bottom>
            <template #activator="{ on: inner }">
              <v-btn
                :loading="loading"
                text
                v-on="{ ...outer, ...inner }">
                <span :class="{warn: sumItem.warn}" v-text="sumItem.value"/>
              </v-btn>
            </template>
            <span v-t="'balance'"/>
          </v-tooltip>
        </slot>
      </template>
      <v-list>
        <v-list-item v-if="loading">
          <v-list-item-title class="text-center">
            <v-progress-linear
              indeterminate
              class="load-progress"
              color="primary"/>
          </v-list-item-title>
        </v-list-item>
        <template v-else>
          <v-list-item>
            <v-list-item-title>
              <span class="text-body-2">
                {{ $t ('title', {client: clientName}) }}
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item v-if="balancesNotAvailable">
            <v-list-item-title>
              <i>{{ $t (balancesError ? 'error' : 'notAvailable') }}</i>
            </v-list-item-title>
          </v-list-item>
          <template v-else>
            <v-subheader>{{ totalTitle }}</v-subheader>
            <v-tooltip
              top
              :disabled="!totalBelowMinBalance">
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title class="text-h6 text-left">
                    {{ sumItem.currency }}
                  </v-list-item-title>
                  <v-list-item-title :class="['title text-right', {warn: sumItem.warn}]">
                    {{ sumItem.value }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <span v-t="'total.belowMin'"/>
            </v-tooltip>
            <v-subheader
              v-if="balanceItems.length > 0"
              v-t="'currencies'"/>
            <template v-for="(balance, index) in balanceItems">
              <v-divider
                v-if="index > 0 && (balance.currency < balanceItems[index-1].currency)"
                :key="`d-${balance.currency}`"/>
              <v-list-item
                :key="`b-${balance.currency}`"
                :color="balance.color">
                <v-list-item-title class="text-left">
                  {{ balance.currency }}
                </v-list-item-title>
                <v-list-item-title :style="{color: balance.color}" class="text-right" :title="balance.text">
                  {{ balance.text }}
                </v-list-item-title>
                <v-list-item-action
                  v-if="!disableActions && actionButtons (balance).length"
                  class="actions">
                  <v-tooltip
                    v-for="button in actionButtons (balance)"
                    :key="button.icon"
                    top>
                    <template #activator="{ on }">
                      <v-btn
                        small
                        class="mr-1"
                        icon outlined v-on="on"
                        @click="button.action (balance)">
                        <v-icon>{{ button.icon }}</v-icon>
                      </v-btn>
                    </template>
                    <span v-t="button.titleKey"/>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </template>
          </template>
        </template>
        <v-divider v-if="creditLimit || warningThreshold"/>
        <v-row v-if="creditLimit" class="pa-3" justify="space-between" align="center">
          <v-col class="align-center align-content-center justify-center">
            <v-icon small class="mr-1">
              pan_tool
            </v-icon>
            {{ $t ('creditLimit') }}:
          </v-col>
          <v-col class="shrink text-no-wrap">
            {{ formatMoneyAmount (creditLimit.value, creditLimit.currency) }}
          </v-col>
        </v-row>
        <v-row v-if="warningThreshold" class="pa-3" justify="space-between" align="center">
          <v-col class="align-center align-content-center justify-center">
            <v-icon small class="mr-1">
              notifications_active
            </v-icon>
            {{ $t ('warningThreshold') }}:
          </v-col>
          <v-col class="shrink">
            {{ formatMoneyAmount (warningThreshold.value, warningThreshold.currency) }}
          </v-col>
        </v-row>
      </v-list>
    </v-menu>
    <currency-converter-dialog
      v-model="currencyConverter.visible"
      :currency="currencyConverter.currency"
      :amount="currencyConverter.amount"
      :max="currencyConverter.max"/>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'

  import CurrencyConverterDialog from '@/app/core/components/App/CurrencyConverterDialog'

  export default {
    name: 'AccountBalance',

    components: {CurrencyConverterDialog},

    props: {
      clientType: {
        type: String,
        required: true
      },
      clientId: {
        type: Number,
        required: true
      },
      isOwn: {
        type: Boolean,
        default: false
      },
      disableActions: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        menu: false,
        loading: false,
        error: false,
        clientName: '',
        balances: [],
        totalBalance: null,
        minBalance: null,
        warningThreshold: null,
        isMirrorAccount: false,
        creditLimit: null,
        currencyConverter: {visible: false, currency: null}
      }
    },

    computed: {

      totalBelowMinBalance () {
        return this.totalBalance && this.minBalance &&
          this.totalBalance.value < this.minBalance.value
      },

      sumItem () {
        if (this.totalBalance === null) {
          return {
            currency: '\u03A3',
            value: this.$t ('total.notAvailable')
          }
        } else {
          return {
            currency: '\u03A3',
            value: this.formatAccountBalance (this.totalBalance),
            warn: this.totalBalance.value < 0 || this.totalBelowMinBalance
          }
        }
      },

      totalTitle () {
        return (this.balances.length === 0 || (this.balances.length === 1 && this.totalBalance &&
          this.balances[0].currency === this.totalBalance.currency))
          ? this.$t ('total.exact')
          : this.$t ('total.approx')
      },

      balanceItems () {
        const compareBalanceItems = (a, b) => {
          let res

          // items with 0 value at the end
          if (a.value === 0 && b.value !== 0) {
            res = 1
          } else {
            if (a.value !== 0 && b.value === 0) {
              res = -1
            } else {
              res = a.currency.localeCompare (b.currency)
            }
          }

          return res
        }

        return this.balances.map (b => ({
          currency: b.currency,
          text: this.formatAccountBalance (b),
          value: b.value,
          color: b.value < 0 ? 'red' : ''
        })).sort (compareBalanceItems)
      },

      balancesNotAvailable () {
        return !this.isMirrorAccount && this.balances.length === 0
      },

      balancesError () {
        return this.error
      }
    },

    watch: {
      menu (newVal) {
        if (newVal) {
          this.loadAccountBalances ()
        }
      },

      clientId () {
        this.loadAccountBalances ()
      }
    },

    created () {
      this.loadAccountBalances ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData',
        clientNameExt: 'cache/getClientNameExt'
      }),

      actionButtons ({value}) {
        const item = (icon, suffix, action) => ({
          icon,
          titleKey: `action.${suffix}`,
          action
        })

        return value
          ? [
            item ('clear', 'close', this.onCloseCurrency),
            ...(
              value > 0
                ? [item ('loop', 'convert', this.onConvertCurrency)]
                : []
            )
          ]
          : []
      },

      /**
       * Function to be called on convert currency
       *
       * @param {String} currency     the currency for which the action should
       *                              be called
       * @param {Number} value        actual balance for the currency
       */
      onConvertCurrency ({currency, value}) {
        this.menu = false

        this.currencyConverter = {
          visible: true,
          currency,
          amount: undefined,
          max: value
        }
      },

      /**
       * Function to be called on close currency balance
       *
       * @param {String} currency     the currency for which the action should
       *                              be called
       * @param {Number} value        actual balance for the currency
       */
      onCloseCurrency ({currency, value}) {
        this.menu = false

        this.currencyConverter = {
          visible: true,
          currency,
          amount: value
        }
      },

      /**
       * Load the account balances
       */
      loadAccountBalances () {
        this.loading = true
        this.error = false
        this.clientName = ''
        this.balances = []
        this.minBalance = null
        this.isMirrorAccount = false

        this.fetchData ({
          op: 'client/data/balances',
          params: {
            id: this.clientId
          },
          cb: data => {
            const balances = data.balances
            this.clientName = this.getClientName (balances.clientId)
            this.balances = balances.balances
            this.minBalance = balances.minBalance
            this.totalBalance = balances.totalBalance
            this.isMirrorAccount = balances.mirrorAccount
            this.warningThreshold = balances.warningThreshold
            this.creditLimit = balances.currentCreditLimit

            this.$emit ('loaded', {
              sumItem: this.sumItem
            })
          },
          cbError: () => {
            this.error = true
          },
          cbFinal: () => {
            this.loading = false
          }
        })
      },

      /**
       * Get the display name of the client with the given ID
       *
       * @param {Number} clientId     the client ID
       */
      async getClientName (clientId) {
        this.clientName = await this.clientNameExt (clientId)
      },

      /**
       * Format the given account balance with respect to the selected locale.
       *
       * @param {Object} balance      the balance to format
       * @return {Object}             the formatted balance
       */
      formatAccountBalance (balance) {
        return this.formatMoneyAmount (balance.value, balance.currency)
      }
    }
  }
</script>

<style scoped>
.load-progress {
  width: 200px
}

.actions {
  min-width: 50px;
  margin-left: 14px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.warn {
  color: red;
}
</style>
