<messages>["../ClientCreateUpdate"]</messages>

<template>
  <v-row no-gutters align="center">
    <v-col md="10" sm="9" cols="9">
      {{ namesReduced }}
    </v-col>
    <v-col md="2" sm="3" cols="3" class="text-right">
      <base-dialog
        v-model="dialog"
        scrollable
        mw2>
        <template #activator="{ on: outer }">
          <v-tooltip top>
            <template #activator="{ on: inner }">
              <v-icon v-on="{ ...outer, ...inner }">
                info
              </v-icon>
            </template>
            {{ $t ('label.click') }}
          </v-tooltip>
        </template>
        <client-group-info-card :groups="groups" :names="groupNames" @close="dialog = false"/>
      </base-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import ClientGroupInfoCard from './ClientGroupInfoCard'
  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    components: {
      ClientGroupInfoCard,
      BaseDialog
    },

    props: {
      groups: {
        type: Array,
        required: true
      },

      groupNames: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        dialog: false
      }
    },

    computed: {
      namesReduced () {
        const reducer = (acc, g) => acc === '' ? g : acc + ', ' + g

        return this.groupNames && this.groupNames.length > 0
          ? this.groupNames.reduce (reducer, '')
          : this.groups.reduce (reducer, '')
      }
    }
  }
</script>
